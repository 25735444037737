import { Link } from "react-router-dom";

export default function SideMenuItem({ to, label, isActive }) {
  return (
    <li className="flex">
      <Link
        className={`${
          isActive
            ? "text-warning border-r-8 border-warning bg-white font-black"
            : "hover:bg-white hover:text-warning border-transparent hover:border-warning border-r-0 font-bold"
        } w-full py-3 px-6 duration-500 ease-in-out transform`}
        to={to}
      >
        {isActive}
        {label}
      </Link>
    </li>
  );
}
