import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import editorConfig from "../../config/editorConfig";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-tailwindcss-select";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useAuth } from "../../contexts/auth/AuthContext";
import axiosClient from "../../axios-client";
import { handleFetchError } from "../../helper";

export default function MineralsRegionCreatePage() {
  const { mineralId } = useParams();
  const { authState, authDispatch } = useAuth();
  const navigate = useNavigate();


  const [countriesOptions, setCountriesOptions] = useState([]);
  const [isCountriesLoading, setCountriesLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [mineral, setMineral] = useState(null);
  const [hasCountry, setHasCountry] = useState(true);

  const bodyArRef = useRef("");
  const bodyEnRef = useRef("");
  const bodyFrRef = useRef("");

  const sourceArRef = useRef("");
  const sourceEnRef = useRef("");
  const sourceFrRef = useRef("");

  const buttonRef = useRef(null);

  const getCountriesNotYetAdded = () => {
    setCountriesLoading(true);
    axiosClient
      .get(`/minerals/${mineralId}/countries/not_added`)
      .then(({ data }) => {
        const countries = data.countries;
        let options = [];
        countries.forEach((country) => {
          options.push({
            value: country.id,
            label: country.name_ar,
            image: country.image,
          });
        });

        setCountriesOptions(options);
      })
      .catch(({ error }) => {
        handleFetchError(error, authDispatch, navigate);
      }).finally(() => {
        setCountriesLoading(false);
      });
  }

  const getMineral = () => {
    setCountriesLoading(true);
    axiosClient
      .get(`/minerals/${mineralId}`)
      .then(({ data }) => {
        setMineral(data.mineral);
      })
      .catch(({ error }) => {
        handleFetchError(error, authDispatch, navigate);
      }).finally(() => {
      });
  }

  useEffect(() => {
    if (mineralId) {
      getCountriesNotYetAdded();
      getMineral();
    }
  }, [mineralId]);

  const handleCountrySelectChange = (value) => {
    setSelectedCountry(value);
  };

  async function handleEditMineralCountriesButton() {
    buttonRef.current.disabled = true;
    buttonRef.current.innerText = "يرجى الانتظار...";

    const newMineralFormData = new FormData();

    newMineralFormData.append(
      "country_id",
      hasCountry ? selectedCountry?.value : 22
    );

    newMineralFormData.append("body_ar", bodyArRef.current.getContent());
    newMineralFormData.append("body_en", bodyEnRef.current.getContent());
    newMineralFormData.append("body_fr", bodyFrRef.current.getContent());

    newMineralFormData.append("source_ar", sourceArRef.current.getContent());
    newMineralFormData.append("source_ar", sourceEnRef.current.getContent());
    newMineralFormData.append("source_ar", sourceFrRef.current.getContent());

    axiosClient
      .post(`/minerals/${mineralId}/country`, newMineralFormData)
      .then(() => {
        toast.success("تمت إضافة البلد بنجاح");
        navigate("/minerals/countries");
      })
      .catch(({ error }) => {
        handleFetchError(error, authDispatch, navigate);
      }).finally(() => {
        buttonRef.current.disabled = false;
        buttonRef.current.innerText = "أضف";
      });
  }

  return (
    <>
      <div className="col-span-12">
        <div className="flex mb-2">
          <Link
            className="bg-warning p-2 hover:bg-warning/80"
            to={`/minerals/countries`}
          >
            <MdOutlineArrowForwardIos size={22} />
          </Link>
          <h1 className="text-3xl  border-r-[6px] border-warning pr-2 font-bold">
            إضافة الوصف
          </h1>
        </div>
      </div>

      <div className="flex flex-col space-y-2 col-span-6">
        {mineral ? (
          <>
            <label className="font-bold">المعدن</label>
            <div className="py-2 px-2 rounded-[6px] bg-muted w-full select-none">
              {mineral.name_ar}
            </div>
          </>
        ) : (
          <>
            <label className="w-[100px] h-[22px] bg-gray-300"></label>
            <div className="w-full h-[40px] bg-gray-300 rounded-[6px]" />
          </>
        )}
      </div>

      <div className="flex flex-col space-y-2 col-span-6">
        <label className="flex items-center">
          <did className="font-bold">اختر الدولة</did>
          {countriesOptions.length == 21 && (
            <input
              checked={hasCountry}
              onChange={() => setHasCountry(!hasCountry)}
              className="mr-2 "
              type="checkbox"
              name="hasCountry"
            />
          )}
        </label>
        {hasCountry ? (
          <Select
            value={selectedCountry}
            onChange={handleCountrySelectChange}
            options={countriesOptions}
            loading={isCountriesLoading}
            isDisabled={isCountriesLoading}
            isSearchable
            placeholder="الدولة"
            isClearable
            classNames={{
              searchContainer: "px-4 pb-2 relative w-full",
              searchBox: "w-full outline-none py-2 px-4 rounded-md bg-muted/20",
              searchIcon: "absolute left-6 w-6 h-6 top-1.5",
            }}
            formatOptionLabel={(data) => (
              <li
                dir="rtl"
                className={`block transition duration-200 px-4 py-4 cursor-pointer select-none truncate rounded-md mt-2 ${data.isSelected
                  ? `bg-blue-200 text-black font-bold`
                  : `text-black font-semibold`
                  }`}
              >
                <div className="flex items-center space-x-4 space-x-reverse">
                  <img
                    className="w-[32px] rounded"
                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${data.image}`}
                    alt=""
                  />
                  <div>{data.label}</div>
                </div>
              </li>
            )}
          />
        ) : (
          <div className="w-full select-none bg-gray-200 rounded-[6px] py-2 px-6">
            ليس له دولة
          </div>
        )}
      </div>

      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        وصف
      </div>

      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالعربية *</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (bodyArRef.current = editor)}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالإنجليزية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (bodyEnRef.current = editor)}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالفرنسية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (bodyFrRef.current = editor)}
          />
        </div>
      </div>
      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        مصدر
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالعربية</label>
        <Editor
          apiKey={process.env.REACT_APP_TINY_KEY}
          init={editorConfig}
          onInit={(evt, editor) => (sourceArRef.current = editor)}
        />
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالإنجليزية</label>
        <Editor
          apiKey={process.env.REACT_APP_TINY_KEY}
          init={editorConfig}
          onInit={(evt, editor) => (sourceEnRef.current = editor)}
        />
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالفرنسية</label>
        <Editor
          apiKey={process.env.REACT_APP_TINY_KEY}
          init={editorConfig}
          onInit={(evt, editor) => (sourceFrRef.current = editor)}
        />
      </div>

      <div className="col-span-12 flex">
        <button
          ref={buttonRef}
          onClick={handleEditMineralCountriesButton}
          className="py-3 px-6 bg-blue-600 hover:bg-blue-500 w-full text-white font-bold duration-150 ease-in-out transform"
        >
          أضف
        </button>
      </div>
    </>
  );
}
