import React, { useEffect, useState } from "react";
import { LuFolder, LuFolderOpen } from "react-icons/lu";
import { MdEdit, MdOutlineAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import OpportunityTableRowDetails from "./OpportunityTableRowDetails";
import { useAuth } from "../../contexts/auth/AuthContext";

export default function OpportunityTableRow({ country }) {
  const { authState } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [opportunities, setOpportunities] = useState(null);

  useEffect(() => {
    async function fetchOpportunities() {
      try {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/countries/${country.id}/opportunities`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: authState.token,
          },
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error);
        }
        const opportunities = data.opportunities;

        setOpportunities(opportunities);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    if (isOpen) {
      fetchOpportunities();
    }

    return () => {};
  }, [isOpen, authState.token]);

  return (
    <>
      <tr key={country.id} className="border odd:bg-muted/30 group ">
        <td
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="border-l group-hover:bg-muted/50"
        >
          <div className="py-3 px-4 cursor-pointer flex justify-center w-full h-full">
            {!isOpen && <LuFolder size={22} />}
            {isOpen && <LuFolderOpen size={22} />}
          </div>
        </td>
        <td className="py-2 px-4 border-l group-hover:bg-muted/50">
          {country.name_ar}
        </td>
        <td className="py-2 px-4 border-l group-hover:bg-muted/50">
          <div className="flex flex-wrap gap-2 items-start justify-start">
            {country.minerals.map((mineral) => {
              return (
                <div key={mineral.id} className="w-10 h-10 overflow-hidden">
                  <img
                    title={mineral.name_ar}
                    className="object-cover w-full h-full"
                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
                    alt={mineral.image}
                  />
                </div>
              );
            })}
          </div>
        </td>
        <td className="py-2 px-4 border-l group-hover:bg-muted/50">
          {country.count}
        </td>

        <td className="py-2 px-4 border-l group-hover:bg-muted/50">
          <div className="flex justify-start gap-2">
            <Link
              title="إضافة فرصة"
              className="bg-success p-2 rounded-[6px] hover:bg-success/70 text-white"
              to={`/opportunity/countries/${country.id}`}
            >
              <MdOutlineAdd size={18} />
            </Link>
            {country.count != 0 && (
              <Link
                title="تعديل المصدر"
                className="bg-muted p-2 rounded-[6px] hover:bg-blue-200"
                to={`/opportunities/countries/${country.id}/source/edit`}
              >
                <MdEdit size={18} />
              </Link>
            )}
          </div>
        </td>
      </tr>
      {isOpen && (
        <OpportunityTableRowDetails
          isLoading={isLoading}
          hasData={opportunities && opportunities.length != 0}
          opportunities={opportunities}
          countryId={country.id}
        />
      )}
    </>
  );
}

// :
