import { useEffect } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import OpportunityTableBody from "../../components/opportunities/OpportunityTableBody";
import { useOpportunities } from "../../contexts/opportunities/OpportunitiesContext";
import { OPPORTUNITIES_ACTIONS } from "../../contexts/opportunities/opportunitiesActions";
import { useAuth } from "../../contexts/auth/AuthContext";

export default function Opportunities() {
  const { opportunitiesState, opportunitiesDispatch } = useOpportunities();
  const { authState } = useAuth();

  useEffect(() => {
    async function fetchCountriesOpportunities() {
      try {
        opportunitiesDispatch({
          type: OPPORTUNITIES_ACTIONS.FETCH_DATA,
        });

        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/countries/opportunities`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: authState.token,
          },
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error);
        }

        const countries = data.countries;

        opportunitiesDispatch({
          type: OPPORTUNITIES_ACTIONS.SET_COUNTRIES,
          payload: {
            countries: countries,
          },
        });
      } catch (error) {
        opportunitiesDispatch({
          type: OPPORTUNITIES_ACTIONS.SET_IS_ERROR,
        });
      }
    }
    fetchCountriesOpportunities();
    return () => {};
  }, [authState.token]);

  return (
    <>
      <div className="col-span-12">
        <div className="flex mb-2">
          <Link className="bg-warning p-2 hover:bg-warning/80" to={`/`}>
            <MdOutlineArrowForwardIos size={22} />
          </Link>
          <h1 className="text-3xl  border-r-[6px] border-warning pr-2 font-bold">
            الفرص الاستثمارية
          </h1>
        </div>
      </div>
      <div className="col-span-12 overflow-x-scroll">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="w-8"></th>
              <th className="text-right whitespace-wrap py-2 px-4 font-semibold bg-primary text-white">
                الدولة
              </th>
              <th className="text-right whitespace-wrap py-2 px-4 font-semibold bg-primary text-white">
                المعادن
              </th>
              <th className="text-right whitespace-wrap py-2 px-4 font-semibold bg-primary text-white">
                عدد الفرص
              </th>
              <th className="whitespace-wrap py-2 px-4 font-semibold bg-primary text-white">
                Actions
              </th>
            </tr>
          </thead>
          <OpportunityTableBody
            isLoading={opportunitiesState.isLoading}
            isError={opportunitiesState.isError}
            countries={opportunitiesState.countries}
          />
        </table>
      </div>
    </>
  );
}
