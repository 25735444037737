import React from "react";
import SideMenuItem from "./SideMenuItem";
import { useLocation } from "react-router-dom";

export default function SideMenu() {
  const location = useLocation();
  return (
    <div className="col-span-3 xl:col-span-2 relative text-primary bg-gray-200 h-full">
      <div className="absolute top-0 left-0 right-0 bottom-0 ">
        <div className="sticky top-0 right-0 w-full flex flex-col items-center">
          <div dir="ltr" className="w-full p-6 bg-success">
            <div className="font-semibold text-white">Admin</div>
            <div className="text-sm text-muted">admin@apfm.org</div>
          </div>
          <ul className="w-full">
            <SideMenuItem
              label={`المعادن`}
              to={`/minerals`}
              isActive={location.pathname === "/minerals"}
            />
            {/* <SideMenuItem
              label={`أسعار المعادن`}
              to={`/minerals/prices`}
              isActive={location.pathname === "/minerals/prices"}
            /> */}
            <SideMenuItem
              label={`المعادن في المنطقة`}
              to={`/minerals/countries`}
              isActive={location.pathname === "/minerals/countries"}
            />
            <SideMenuItem
              label={`الفرص الاستثمارية`}
              to={`/opportunities`}
              isActive={location.pathname === "/opportunities"}
            />
            {/* <SideMenuItem
                            label={`المستخدمين`}
                            to={`/users`}
                            isActive={location.pathname === "/users"}
                        /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
