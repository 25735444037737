import { toast } from "react-toastify";
import { AUTH_ACTIONS } from "./contexts/auth/authActions";

const showToastError = (message) => {
    toast.error(message, {
        position: 'top-left',
        duration: 5000, // Toast will stay for 5 seconds
        style: {
            borderRadius: '5px', // Slightly larger border radius for a smoother look
            backgroundColor: '#ff6b6b', // Light red background color for errors
            color: '#fff', // White text color for better contrast
            padding: '10px', // Additional padding for better readability
            fontSize: '14px', // Slightly larger font for better readability
        },
        iconTheme: {
            primary: '#ff6b6b', // Matching icon color with background
            secondary: '#fff', // White secondary color for the icon
        },
    });
};

const showToastSuccess = (message) => {
    toast.success(message, {
        position: 'top-left', // Positioning on the top-right, commonly used for success messages
        duration: 5000, // Toast will stay for 4 seconds
        style: {
            borderRadius: '5px', // Consistent border radius
            backgroundColor: '#4CAF50', // Green background color to represent success
            color: '#fff', // White text color for contrast
            padding: '10px', // Consistent padding
            fontSize: '14px', // Consistent font size
        },
        iconTheme: {
            primary: '#4CAF50', // Matching the success green
            secondary: '#fff', // White secondary color for the icon
        },
    });
};



const handleFetchError = (error, authDispatch, navigate) => {
    const { response } = error;
    if (
        response.status === 401 ||
        (response.status === 500 && response.data.error === 'Unauthenticated.')
    ) {
        showToastError('انتهت صلاحية الجلسة الخاصة بك');
        authDispatch({ type: AUTH_ACTIONS.LOGOUT });
        navigate('/login', { replace: true });
    } else {
        showToastError(response.data.error);
    }
};

export {
    showToastError,
    showToastSuccess,
    handleFetchError
};
