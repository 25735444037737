import { OPPORTUNITIES_ACTIONS } from "./opportunitiesActions";

export const opportunitiesReducer = (state, action) => {
  switch (action.type) {
    case OPPORTUNITIES_ACTIONS.FETCH_DATA:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case OPPORTUNITIES_ACTIONS.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload.countries,
        isLoading: false,
        isError: false,
      };
    case OPPORTUNITIES_ACTIONS.SET_IS_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
