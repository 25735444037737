import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../../axios-client";
import Select from "react-tailwindcss-select";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function MineralPricesAdd() {
  const navigate = useNavigate();

  const [selectedMineral, setSelectedMineral] = useState(null);
  const [mineralOptions, setMineralOptions] = useState([]);

  const [isMineralsDataLoading, setIsMineralsDataLoading] = useState(true);

  const buttonRef = useRef(null);

  const symbolRef = useRef(null);
  const sourceNameRef = useRef(null);
  const unitArRef = useRef(null);
  const unitEnRef = useRef(null);
  const unitFrRef = useRef(null);
  const unitSymbolRef = useRef(null);

  function handleOnClickAddMineralPriceSource() {
    buttonRef.current.disabled = true;
    buttonRef.current.innerText = "يرجى الانتظار...";
    const payload = {
      mineral_id: selectedMineral?.value || null,
      symbol: symbolRef.current?.value || "",
      source_name: sourceNameRef.current?.value || "",
      unit_ar: unitArRef.current?.value || "",
      unit_en: unitEnRef.current?.value || "",
      unit_fr: unitFrRef.current?.value || "",
      unit_symbol: unitSymbolRef.current?.value || "",
    };

    console.log(payload);

    axiosClient
      .post("/dashboard/minerals/price", payload)
      .then(({ data }) => {
        toast.success(`تمت إضافة  (${symbolRef.current.value}) بنجاح!`);
        navigate("/minerals/prices");
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      })
      .finally(() => {
        buttonRef.current.disabled = false;
        buttonRef.current.innerText = "أضف";
      });
  }

  function handleOnMineralChange(mineralOption) {
    setSelectedMineral(mineralOption);
  }

  useEffect(() => {
    async function fetchMinerals() {
      setIsMineralsDataLoading(true);
      axiosClient
        .get(`/dashboard/minerals/minify`)
        .then(({ data }) => {
          console.log(data);
          const minerals = data.minerals;

          let options = [];

          minerals.forEach((mineral) => {
            options.push({
              value: mineral.id,
              label: mineral.name_ar,
              image: mineral.image,
            });
          });

          setMineralOptions(options);
        })
        .catch(({ error }) => {
          setIsMineralsDataLoading(false);
        })
        .finally(() => {
          setIsMineralsDataLoading(false);
        });
    }
    fetchMinerals();
    return () => {};
  }, []);

  return (
    <>
      <div className="flex flex-col space-y-2 col-span-6">
        <label className="flex items-center">
          <div className="font-bold">اختر المعدن</div>
        </label>
        <Select
          value={selectedMineral}
          onChange={handleOnMineralChange}
          options={mineralOptions}
          loading={isMineralsDataLoading}
          isDisabled={isMineralsDataLoading}
          isSearchable
          placeholder={`المعدن`}
          classNames={{
            searchContainer: `px-4 pb-2 relative w-full`,
            searchBox: `w-full outline-none py-2 px-4 rounded-md bg-muted/20`,
            searchIcon: `absolute left-6 w-6 h-6 top-1.5`,
          }}
          formatOptionLabel={(data) => (
            <li
              dir={`rtl`}
              className={`block transition duration-200 px-4 py-4 cursor-pointer select-none truncate rounded-md mt-2 ${
                data.isSelected
                  ? `bg-blue-200 text-black font-bold`
                  : `text-black font-semibold`
              }`}
            >
              <div className={`flex items-center space-x-4 space-x-reverse`}>
                <img
                  className={`w-[32px] rounded`}
                  src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${data.image}`}
                  alt={data.label}
                  title={data.label}
                />
                <div>{data.label}</div>
              </div>
            </li>
          )}
        />
      </div>
      <div className="col-span-6"></div>
      <div className="flex flex-col space-y-2 col-span-6">
        <label className="font-bold">رمز*</label>
        <input
          ref={symbolRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="mineral_symbol"
        />
      </div>
      <div className="col-span-6"></div>
      <div className="flex flex-col space-y-2 col-span-6">
        <label className="font-bold">مصدر *</label>
        <input
          ref={sourceNameRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="mineral_symbol"
        />
      </div>
      <div className="col-span-6"></div>
      <div className="flex flex-col space-y-2 col-span-3">
        <label className="font-bold">وحدة القياس بالعربية*</label>
        <input
          ref={unitArRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="mineral_symbol"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-3">
        <label className="font-bold">وحدة القياس بالإنجليزية*</label>
        <input
          ref={unitEnRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="mineral_symbol"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-3">
        <label className="font-bold">وحدة القياس بالفرنسية*</label>
        <input
          ref={unitFrRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="mineral_symbol"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-3">
        <label className="font-bold">رمز وحدة القياس*</label>
        <input
          ref={unitSymbolRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="mineral_symbol"
        />
      </div>
      <div className="col-span-12 flex">
        <button
          ref={buttonRef}
          onClick={handleOnClickAddMineralPriceSource}
          className="py-3 px-6 bg-blue-600 hover:bg-blue-500 w-full text-white font-bold duration-150 ease-in-out transform"
        >
          أضف
        </button>
      </div>
    </>
  );
}
