import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  MdEdit,
  MdOutlineAdd,
  MdOutlineArrowForwardIos,
  MdOutlineClose,
} from "react-icons/md";

import axiosClient from "../../axios-client";
import MineralStatus from "../../components/minerals/MineralStatus";
import { FaCheck, FaEye } from "react-icons/fa6";
import MineralType from "../../components/minerals/MineralType";
import { useAuth } from "../../contexts/auth/AuthContext";
import { handleFetchError } from "../../helper";
import { useNavigate } from "react-router-dom";
import MineralHasValueChains from "../../components/minerals/MineralHasValueChains";
import MineralHasStudies from "../../components/minerals/MineralHasStudies";

export default function MineralsPage() {
  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const [minerals, setMinerals] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const getAllMinerals = () => {
    setisLoading(true);
    axiosClient
      .get(`/minerals`)
      .then(({ data }) => {
        const { minerals } = data;
        setMinerals(minerals);
      })
      .catch(({ error }) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    getAllMinerals();
  }, []);

  return (
    <>
      <div className="col-span-12">
        <div className="flex justify-between">
          <Link
            className="bg-warning py-3 px-4 rounded-lg hover:bg-warning/80"
            to={`/`}
          >
            <MdOutlineArrowForwardIos size={22} />
          </Link>
          <Link
            className="bg-primary py-3 px-4 mr-3 hover:bg-success flex items-center gap-4 rounded-lg text-white"
            to={`/mineral`}
          >
            <MdOutlineAdd size={22} />
            <span>إضافة معدن</span>
          </Link>
        </div>
      </div>
      <div className="col-span-12 overflow-x-scroll">
        <table className="table-auto w-full ">
          <thead>
            <tr>
              <th className="whitespace-nowrap text-start rounded-tr-xl py-3 px-4 font-semibold bg-primary text-white">
                المعدن
              </th>
              <th className="whitespace-nowrap py-3 px-4 font-semibold bg-primary text-white">
                رمز
              </th>
              <th className="whitespace-nowrap py-3 px-4 font-semibold bg-primary text-white">
                النوع
              </th>
              <th className="whitespace-nowrap py-3 px-4 font-semibold bg-primary text-white">
                سلسلة القيمة
              </th>
              <th className="whitespace-nowrap py-3 px-4 font-semibold bg-primary text-white">
                رابط الدراسات
              </th>
              <th className="whitespace-nowrap py-3 px-4 rounded-tl-xl font-semibold bg-primary text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              minerals.map(function (mineral) {
                return (
                  <tr
                    key={mineral.id}
                    className="bg-light/30 odd:bg-muted/20 group"
                  >
                    <td className="flex py-2 px-4 border-l border-primary/20 group-hover:bg-success/20">
                      <div className="flex gap-4">
                        <div className="w-10 h-10 overflow-hidden">
                          <img
                            title={mineral.name_ar}
                            className="object-cover w-full h-full"
                            src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
                            alt={`${mineral.name_ar}`}
                          />
                        </div>
                        <div>
                          <div className="font-bold">{mineral.name_ar}</div>
                          <div>{mineral.name_en}</div>
                        </div>
                      </div>
                    </td>
                    <td className="py-2 px-4 border-l border-primary/20 text-center font-bold group-hover:bg-success/20">
                      {mineral.symbol}
                    </td>
                    <td className="py-2 px-4 border-l border-primary/20 text-center font-bold group-hover:bg-success/20">
                      <div className="flex justify-center">
                        <MineralType type={mineral.type} />
                      </div>
                    </td>

                    <td className="py-2 px-4 border-l border-primary/20 group-hover:bg-success/20">
                      <div className="flex justify-center gap-2">
                        <MineralHasValueChains
                          valueChain={mineral.value_chains}
                          language={"ar"}
                        />
                        <MineralHasValueChains
                          valueChain={mineral.value_chains}
                          language={"en"}
                        />
                        <MineralHasValueChains
                          valueChain={mineral.value_chains}
                          language={"fr"}
                        />
                      </div>
                    </td>
                    <td className="py-2 px-4 border-l border-primary/20 group-hover:bg-success/20">
                      <div className="flex justify-center">
                        <MineralHasStudies studies={mineral.studies} />
                      </div>
                    </td>

                    <td className="py-2 px-4 group-hover:bg-success/20">
                      <div className="flex justify-end gap-2">
                        <Link
                          title="تعديل"
                          className="bg-muted p-2 rounded-[6px] hover:bg-blue-200"
                          to={`/minerals/${mineral.id}/edit`}
                        >
                          <MdEdit size={18} />
                        </Link>
                        {mineral.status === "PUBLISHED" && (
                          <Link
                            title="مشاهدة معدن على منصة"
                            target="_blanck"
                            className="bg-muted p-2 rounded-[6px] hover:bg-blue-200"
                            to={`https://arabpfm.org/clean-energy-minerals/${mineral.slug}/details?page=1`}
                          >
                            <FaEye size={18} />
                          </Link>
                        )}
                        {/* <div className='bg-danger/50 p-2 rounded-[6px] -hover:bg-danger/80 cursor-not-allowed	' to={``}>
                            <FaTrash size={18} />
                        </div> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
