import { createContext, useContext, useReducer } from "react";

import { opportunitiesInitialState } from "./opportunitiesInitialState";
import { opportunitiesReducer } from "./opportunitiesReducer";
import { Outlet } from "react-router-dom";

const OpportunitiesContext = createContext({
  state: opportunitiesInitialState,
  dispatch: () => {},
});

export const OpportunitiesProvider = () => {
  const [opportunitiesState, opportunitiesDispatch] = useReducer(
    opportunitiesReducer,
    opportunitiesInitialState
  );

  return (
    <OpportunitiesContext.Provider
      value={{ opportunitiesState, opportunitiesDispatch }}
    >
      <Outlet />
    </OpportunitiesContext.Provider>
  );
};

export default OpportunitiesContext;

export const useOpportunities = () => useContext(OpportunitiesContext);
