import { AUTH_ACTIONS } from "./authActions";

export const authReducer = (state, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.LOGIN:
      localStorage.setItem("ACCESS_TOKEN", action.payload.token);
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isLogin: true,
      };
    case AUTH_ACTIONS.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case AUTH_ACTIONS.LOGOUT:
      localStorage.removeItem("ACCESS_TOKEN");
      return {
        ...state,
        user: null,
        token: null,
        isLogin: false,
      };
    default:
      return state;
  }
};
