import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  editOpportunitySource,
  getOpportunitySource,
} from "../../api/opportunities";
import { toast } from "react-toastify";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useAuth } from "../../contexts/auth/AuthContext";

export default function EditOpportunitySource() {
  const { authState } = useAuth();
  const { countryId } = useParams();
  const navigate = useNavigate();

  const [source, setSource] = useState(null);

  const sourceArRef = useRef(null);
  const sourceEnRef = useRef(null);
  const sourceFrRef = useRef(null);

  const sourceLinkRef = useRef(null);
  const buttonRef = useRef(null);

  async function handleOnClickEditButton() {
    try {
      buttonRef.current.disabled = true;
      buttonRef.current.innerText = "يرجى الانتظار...";
      const opportunitySourceData = new FormData();

      opportunitySourceData.append("source_ar", sourceArRef.current.value);
      opportunitySourceData.append("source_en", sourceEnRef.current.value);
      opportunitySourceData.append("source_fr", sourceFrRef.current.value);
      opportunitySourceData.append("source_link", sourceLinkRef.current.value);

      const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/countries/${countryId}/opportunities/source`;

      const response = await fetch(url, {
        method: "POST",
        body: opportunitySourceData,
        headers: {
          Authorization: authState.token,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      toast.success(source ? `تم التحديث بنجاح` : `تم الإضافة بنجاح`);

      navigate("/opportunities");
    } catch (error) {
      toast.error(error.message);
    } finally {
      buttonRef.current.disabled = false;
      buttonRef.current.innerText = source ? `تعديل` : `أضف`;
    }
  }

  useEffect(() => {
    async function fetchOpportunitySource() {
      try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/countries/${countryId}/opportunities/source`;

        const response = await fetch(url, {
          method: "get",
          headers: {
            Authorization: authState.token,
          },
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error);
        }

        const source = data.source;

        setSource(source);
      } catch (error) {}
    }

    fetchOpportunitySource();
    return () => {};
  }, [authState.token, countryId]);

  return (
    <>
      <div className="col-span-12">
        <div className="flex mb-2">
          <Link
            className="bg-warning p-2 hover:bg-warning/80"
            to={`/opportunities`}
          >
            <MdOutlineArrowForwardIos size={22} />
          </Link>
          <h1 className="text-3xl border-r-[6px] border-warning pr-2 font-bold">
            مصدر فرص الاستثمارية
          </h1>
        </div>
      </div>

      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        مصدر
      </div>

      <div dir="rtl" className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالعربية</label>
        <input
          defaultValue={source && source.source_ar}
          ref={sourceArRef}
          className="outline-none border border-muted py-2 px-6"
          type="url"
        />
      </div>

      <div dir="rtl" className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالإنجليزية</label>
        <input
          defaultValue={source && source.source_en}
          ref={sourceEnRef}
          className="outline-none border border-muted py-2 px-6"
          type="url"
        />
      </div>

      <div dir="rtl" className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالفرنسية</label>
        <input
          defaultValue={source && source.source_fr}
          ref={sourceFrRef}
          className="outline-none border border-muted py-2 px-6"
          type="url"
        />
      </div>

      <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">الرابط</label>
        <input
          defaultValue={source && source.source_link}
          dir="ltr"
          placeholder="https://..."
          ref={sourceLinkRef}
          className="outline-none border border-muted py-2 px-6"
          type="url"
        />
      </div>
      <div className="col-span-12 flex">
        <button
          ref={buttonRef}
          onClick={handleOnClickEditButton}
          className="py-3 px-6 bg-blue-600 hover:bg-blue-500 w-full text-white font-bold"
        >
          {source ? `تعديل` : `أضف`}
        </button>
      </div>
    </>
  );
}
