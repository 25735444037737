import { createBrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import Home from "./pages/Home";
import Opportunities from "./pages/opportunities/Opportunities";
import MineralsPage from "./pages/minerals/MineralsPage";
import EditMineral from "./pages/minerals/MineralEditPage";
import MineralsRegionEditPage from "./pages/mineral-region/MineralsRegionEditPage";
import AddOpportunity from "./pages/opportunities/AddOpportunity";
import EditOpportunity from "./pages/opportunities/EditOpportunity";
import Login from "./pages/Login";
import { OpportunitiesProvider } from "./contexts/opportunities/OpportunitiesContext";
import EditOpportunitySource from "./pages/opportunities/EditOpportunitySource";
import MineralsRegionPage from "./pages/mineral-region/MineralsRegionPage";
import MineralPrices from "./pages/mineral_prices/MineralPrices";
import MineralPricesAdd from "./pages/mineral_prices/MineralPricesAdd";
import Splash from "./pages/Splash";
import MineralCreatePage from "./pages/minerals/MineralCreatePage";
import MineralsRegionCreatePage from "./pages/mineral-region/MineralsRegionCreatePage";

const router = createBrowserRouter([
  {
    path: ``,
    element: <Splash />,
    children: [
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: "/mineral",
            element: <MineralCreatePage />,
          },
          {
            path: "/minerals",
            element: <MineralsPage />,
          },
          {
            path: "/minerals/:mineralId/edit",
            element: <EditMineral />,
          },
          {
            path: "/minerals/countries",
            element: <MineralsRegionPage />,
          },
          {
            path: "/minerals/prices",
            element: <MineralPrices />,
          },
          {
            path: "/mineral/price",
            element: <MineralPricesAdd />,
          },
          {
            path: "/minerals/:mineralId/countries",
            element: <MineralsRegionCreatePage />,
          },
          {
            path: "/minerals/:mineralId/countries/edit",
            element: <MineralsRegionEditPage />,
          },
          {
            path: "",
            element: <OpportunitiesProvider />,
            children: [
              {
                path: "/opportunities",
                element: <Opportunities />,
              },
              {
                path: "/opportunity/countries/:countryId",
                element: <AddOpportunity />,
              },
              {
                path: "/opportunities/:opportunityId/countries/:countryId",
                element: <EditOpportunity />,
              },
              {
                path: "/opportunities/countries/:countryId/source/edit",
                element: <EditOpportunitySource />,
              },
            ],
          },
        ],
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  }
]);

export default router;
