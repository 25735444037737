import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import editorConfig from "../../config/editorConfig";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import {
  editMineralCountry,
  getMineral,
  getMineralCountries,
  getMineralCountry,
} from "../../api/minerals";
import Select from "react-tailwindcss-select";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useAuth } from "../../contexts/auth/AuthContext";
import axiosClient from "../../axios-client";

export default function MineralsRegionEditPage() {
  const { authState } = useAuth();
  const { mineralId } = useParams();
  const navigate = useNavigate();

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [isCountriesLoading, setCountriesLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [mineral, setMineral] = useState(null);

  const bodyArRef = useRef("");
  const bodyEnRef = useRef("");
  const bodyFrRef = useRef("");

  const sourceArRef = useRef("");
  const sourceEnRef = useRef("");
  const sourceFrRef = useRef("");

  const buttonRef = useRef(null);

  async function fetchCountryMineral(countryId) {
    try {
      resetContents();
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/${mineralId}/countries/${countryId}`;
      console.log(url);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: authState.token,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      const countryMineral = data.mineralCountry;

      bodyArRef.current.setContent(countryMineral.body_ar || "");
      bodyEnRef.current.setContent(countryMineral.body_en || "");
      bodyFrRef.current.setContent(countryMineral.body_fr || "");

      sourceArRef.current.setContent(countryMineral.source_ar || "");
      sourceEnRef.current.setContent(countryMineral.source_en || "");
      sourceFrRef.current.setContent(countryMineral.source_fr || "");
    } catch (error) {
      resetContents();
      console.error(error);
    }
  }

  function resetContents() {
    bodyArRef.current.setContent("");
    bodyEnRef.current.setContent("");
    bodyFrRef.current.setContent("");

    sourceArRef.current.setContent("");
    sourceEnRef.current.setContent("");
    sourceFrRef.current.setContent("");
  }

  async function fetchCountries() {
    setCountriesLoading(true);
    axiosClient.get(`/minerals/${mineralId}/countries`)
      .then(({ data }) => {
        const countries = data.countries;

        let options = [];

        countries.forEach((country) => {
          options.push({
            value: country.id,
            label: country.name_ar,
            image: country.image,
          });
        });
        setCountriesOptions(options);
      })
      .catch((error) => { })
      .finally(() => {

        setCountriesLoading(false);
      })

  }

  useEffect(() => {

    async function fetchMineral() {
      try {
        const mineral = await getMineral(authState.token, mineralId);
        setMineral(mineral);
      } catch (error) {
        console.log(error);
      }
    }
    fetchMineral();
    fetchCountries();
    return () => { };
  }, [mineralId]);

  const handleCountrySelectChange = (selectedCountryOption) => {
    setSelectedCountry(selectedCountryOption);
    fetchCountryMineral(selectedCountryOption.value);
  };

  async function handleEditMineralCountriesButton() {
    try {
      buttonRef.current.disabled = true;
      buttonRef.current.innerText = "يرجى الانتظار...";

      const newMineralCountryFormData = new FormData();

      newMineralCountryFormData.append(
        "body_ar",
        bodyArRef.current.getContent()
      );
      newMineralCountryFormData.append(
        "body_en",
        bodyEnRef.current.getContent()
      );
      newMineralCountryFormData.append(
        "body_fr",
        bodyFrRef.current.getContent()
      );

      newMineralCountryFormData.append(
        "source_ar",
        sourceArRef.current.getContent()
      );
      newMineralCountryFormData.append(
        "source_en",
        sourceEnRef.current.getContent()
      );
      newMineralCountryFormData.append(
        "source_fr",
        sourceFrRef.current.getContent()
      );

      const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/${mineralId}/countries/${selectedCountry?.value}`;

      const response = await fetch(url, {
        method: "POST",
        body: newMineralCountryFormData,
        headers: {
          Authorization: authState.token,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      console.log(data);

      toast.success(`تم تحديث بنجاح`);
      navigate("/minerals/countries");
    } catch (error) {
      toast.error(error.message);
    } finally {
      buttonRef.current.disabled = false;
      buttonRef.current.innerText = "تعديل";
    }
  }

  return (
    <>
      <div className="col-span-12">
        <div className="flex mb-2">
          <Link
            className="bg-warning p-2 hover:bg-warning/80"
            to={`/minerals/countries`}
          >
            <MdOutlineArrowForwardIos size={22} />
          </Link>
          <h1 className="text-3xl border-r-[6px] border-warning pr-2 font-bold">
            تعديل الوصف
          </h1>
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-6">
        <label className="font-bold">المعدن</label>
        {mineral ? (
          <>
            <div className="py-2 px-2 rounded-[6px] bg-muted w-full select-none">
              {mineral.name_ar}
            </div>
          </>
        ) : (
          <>
            <div className="w-full h-[40px] bg-gray-300 rounded-[6px]" />
          </>
        )}
      </div>
      <div className="flex flex-col space-y-2 col-span-6">
        <label className="font-bold">اختر الدولة</label>
        <Select
          value={selectedCountry}
          onChange={handleCountrySelectChange}
          options={countriesOptions}
          loading={isCountriesLoading}
          isDisabled={isCountriesLoading}
          isSearchable
          placeholder="الدولة"
          isClearable
          classNames={{
            searchContainer: "px-4 pb-2 relative w-full",
            searchBox: "w-full outline-none py-2 px-4 rounded-md bg-muted/20",
            searchIcon: "absolute left-6 w-6 h-6 top-1.5",
          }}
          formatOptionLabel={(data) => (
            <li
              dir="rtl"
              className={`block transition duration-200 px-4 py-4 cursor-pointer select-none truncate rounded-md mt-2 ${data.isSelected
                ? `bg-blue-200 text-black font-bold`
                : `text-black font-semibold`
                }`}
            >
              <div className="flex items-center space-x-4 space-x-reverse">
                <img
                  className="w-[32px] rounded"
                  src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${data.image}`}
                  alt=""
                />
                <div>{data.label}</div>
              </div>
            </li>
          )}
        />
      </div>

      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        وصف
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالعربية *</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (bodyArRef.current = editor)}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالإنجليزية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (bodyEnRef.current = editor)}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالفرنسية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (bodyFrRef.current = editor)}
          />
        </div>
      </div>

      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        مصدر
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالعربية</label>
        <Editor
          apiKey={process.env.REACT_APP_TINY_KEY}
          init={editorConfig}
          onInit={(evt, editor) => (sourceArRef.current = editor)}
        />
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالإنجليزية</label>
        <Editor
          apiKey={process.env.REACT_APP_TINY_KEY}
          init={editorConfig}
          onInit={(evt, editor) => (sourceEnRef.current = editor)}
        />
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالفرنسية</label>
        <Editor
          apiKey={process.env.REACT_APP_TINY_KEY}
          init={editorConfig}
          onInit={(evt, editor) => (sourceFrRef.current = editor)}
        />
      </div>

      <div className="col-span-12 flex">
        <button
          ref={buttonRef}
          onClick={handleEditMineralCountriesButton}
          className="py-3 px-6 bg-blue-600 hover:bg-blue-500 w-full text-white font-bold"
        >
          تعديل
        </button>
      </div>
    </>
  );
}
