import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import { useAuth } from "../contexts/auth/AuthContext";
import { AUTH_ACTIONS } from "../contexts/auth/authActions";
import { handleFetchError } from "../helper";

export default function Splash() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const { authState, authDispatch } = useAuth();

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .post("/auth/me")
      .then(({ data }) => {
        if (data.user == null) {
          authDispatch({ type: AUTH_ACTIONS.LOGOUT });
          navigate("/login", { replace: true });
          return;
        }
        console.log(data.user);

        authDispatch({
          type: AUTH_ACTIONS.SET_USER,
          payload: {
            user: data.user,
          },
        });
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [authState.token]);

  if (isLoading) {
    return (
      <div className="z-0 flex h-screen w-full items-center justify-center">
        <div className="flex flex-col justify-center gap-8 text-center">
          <img
            className="h-[256px] w-[256px] animate-pulse"
            src="/assets/logos/app_logo.svg"
            alt="Logo"
          />
        </div>
      </div>
    );
  } else {
    return <Outlet />;
  }
}
