import { toast } from "react-toastify";
import Select from "react-tailwindcss-select";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { getMineralsMinify } from "../../api/minerals";
import { useAuth } from "../../contexts/auth/AuthContext";
import DatePicker from "react-date-picker";

export default function AddOpportunity() {
  const { authState } = useAuth();
  const { countryId } = useParams();

  const navigate = useNavigate();

  const [selectedMinerals, setSelectedMinerals] = useState(null);

  const [mineralsOptions, setMineralsOptions] = useState([]);

  const [country, setCountry] = useState(null);
  const [isMineralsLoading, setMineralsLoading] = useState(true);

  const [hasTime, setHasTime] = useState(false);
  const [hasArea, setHasArea] = useState(false);

  const titleArRef = useRef(null);
  const titleEnRef = useRef(null);
  const titleFrRef = useRef(null);
  const [mineralsIds, setMineralsIds] = useState("");
  const mineralsArRef = useRef(null);
  const mineralsEnRef = useRef(null);
  const mineralsFrRef = useRef(null);
  const typeArRef = useRef(null);
  const typeEnRef = useRef(null);
  const typeFrRef = useRef(null);
  const areaRef = useRef(null);
  const [startedAtDate, setStartedAtDate] = useState(moment(new Date()));
  const attachmentLinkRef = useRef(null);

  const buttonRef = useRef(null);

  const handleMineralSelectChange = (minerals) => {
    let text_ar = "";
    let text_fr = "";
    let text_en = "";
    let ids = "";
    if (minerals) {
      minerals.forEach((mineral, index) => {
        if (index == 0) {
          text_ar += mineral.label;
          if (mineral.name_en) {
            text_en += mineral.name_en;
          }
          if (mineral.name_fr) {
            text_fr += mineral.name_fr;
          }
        } else {
          text_ar += ` و${mineral.label}`;
          if (mineral.name_en) {
            text_en += `, ${mineral.name_en}`;
          }
          if (mineral.name_fr) {
            text_fr += `, ${mineral.name_fr}`;
          }
        }
      });
      minerals.forEach((mineral, index) => {
        if (index == 0) {
          ids += mineral.value;
        } else {
          ids += `,${mineral.value}`;
        }
      });
    }
    setSelectedMinerals(minerals);
    setMineralsIds(ids);
    mineralsArRef.current.value = text_ar;
    mineralsEnRef.current.value = text_en;
    mineralsFrRef.current.value = text_fr;
  };

  async function handleOnClickAddButton() {
    try {
      buttonRef.current.disabled = true;
      buttonRef.current.innerText = "يرجى الانتظار...";
      const opportunityFormData = new FormData();

      opportunityFormData.append("title_ar", titleArRef.current?.value || "");
      opportunityFormData.append("title_en", titleEnRef.current?.value || "");
      opportunityFormData.append("title_fr", titleFrRef.current?.value || "");

      opportunityFormData.append("minerals_ids", mineralsIds || "");

      opportunityFormData.append(
        "minerals_ar",
        mineralsArRef.current?.value || ""
      );
      opportunityFormData.append(
        "minerals_en",
        mineralsEnRef.current?.value || ""
      );
      opportunityFormData.append(
        "minerals_fr",
        mineralsFrRef.current?.value || ""
      );

      opportunityFormData.append("type_ar", typeArRef.current?.value || "");
      opportunityFormData.append("type_en", typeEnRef.current?.value || "");
      opportunityFormData.append("type_fr", typeFrRef.current?.value || "");

      if (hasArea) {
        if (!areaRef.current.value) {
          toast.error("المساحة مطلوبة.");
          return;
        }
        opportunityFormData.append("area", areaRef.current?.value || "");
      }

      if (hasTime) {
        if (!startedAtDate) {
          toast.error("تاريخ الفرصة مطلوب.");
          return;
        }
        opportunityFormData.append(
          "started_at",
          moment(startedAtDate).format("YYYY-MM-DD HH:mm:ss")
        );
      }

      opportunityFormData.append(
        "attachment_link",
        attachmentLinkRef.current?.value || ""
      );

      const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/countries/${countryId}/opportunities`;
      const response = await fetch(url, {
        method: "POST",
        body: opportunityFormData,
        headers: {
          Authorization: authState.token,
        },
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error);
      }

      toast.success(
        `تمت إضافة (${titleArRef.current.value}) إلى (${country.name_ar}) بنجاح!`
      );
      navigate("/opportunities");
    } catch (error) {
      toast.error(error.message);
    } finally {
      buttonRef.current.disabled = false;
      buttonRef.current.innerText = "أضف";
    }
  }

  const handleTimeCheckboxChange = (event) => {
    setHasTime(event.target.checked);
  };

  const handleAreaCheckboxChange = (event) => {
    setHasArea(event.target.checked);
    if (!event.target.checked) {
      areaRef.current = null;
    }
  };

  useEffect(() => {
    async function fetchCountry() {
      try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/countries/${countryId}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: authState.token,
          },
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error);
        }

        const country = data.country;
        setCountry(country);
      } catch (error) {
      } finally {
      }
    }
    async function fetchMineralsLight() {
      try {
        setMineralsLoading(true);
        const minerals = await getMineralsMinify();
        let options = [];
        minerals.forEach((mineral) => {
          options.push({
            value: mineral.id,
            label: mineral.name_ar,
            name_en: mineral.name_en,
            name_fr: mineral.name_fr,
            image: mineral.image,
          });
        });
        setMineralsOptions(options);
      } catch (error) {
      } finally {
        setMineralsLoading(false);
      }
    }

    fetchCountry();
    fetchMineralsLight();
    return () => {};
  }, [authState.token]);

  return (
    <>
      <div className="col-span-12">
        <div className="flex mb-2">
          <Link
            className="bg-warning p-2 hover:bg-warning/80"
            to={`/opportunities`}
          >
            <MdOutlineArrowForwardIos size={22} />
          </Link>
          <h1 className="text-3xl  border-r-[6px] border-warning pr-2 font-bold">
            إضافة فرصة
          </h1>
        </div>
      </div>

      <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">الدولة</label>
        {country ? (
          <div className="flex flex-col space-y-2">
            <div>
              <img
                className="h-16"
                src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                alt={`${country.name_ar}`}
              />
            </div>
            <div>{country.name_ar}</div>
          </div>
        ) : (
          <div className="h-16 w-[104px] bg-gray-200" />
        )}
      </div>

      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold select-none">
        عنوان
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالعربية</label>
        <input
          ref={titleArRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="opportunity_title_ar"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالإنجليزية</label>
        <input
          dir="ltr"
          ref={titleEnRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="opportunity_title_en"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالفرنسية</label>
        <input
          dir="ltr"
          ref={titleFrRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="opportunity_title_fr"
        />
      </div>

      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold select-none">
        خامات
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
        <label>اختر </label>
        <Select
          value={selectedMinerals}
          onChange={handleMineralSelectChange}
          options={mineralsOptions}
          loading={isMineralsLoading}
          isDisabled={isMineralsLoading}
          classNames={{
            searchContainer: "px-4 pb-2 relative w-full",
            searchBox: "w-full outline-none py-2 px-4 rounded-md bg-muted/20",
            searchIcon: "absolute left-6 w-6 h-6 top-1.5",
          }}
          isSearchable
          isMultiple
          isClearable
          placeholder="الخامات"
          formatOptionLabel={(data) => (
            <li
              dir="rtl"
              className={`block transition duration-200 px-4 cursor-pointer select-none truncate rounded-md mt-2 ${
                data.isSelected
                  ? `bg-blue-200 text-black font-bold`
                  : `text-black font-semibold`
              }`}
            >
              <div className="flex items-center space-x-4 space-x-reverse">
                <img
                  className="h-[32px] w-[64px]"
                  src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${data.image}`}
                  alt=""
                />
                <div>{data.label}</div>
              </div>
            </li>
          )}
        />
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالعربية</label>
        <input
          ref={mineralsArRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="opportunity_mienrals_ar"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالإنجليزية</label>
        <input
          dir="ltr"
          ref={mineralsEnRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="opportunity_mienrals_en"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالفرنسية</label>
        <input
          dir="ltr"
          ref={mineralsFrRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="opportunity_mienrals_fr"
        />
      </div>

      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold select-none">
        نوع
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالعربية</label>
        <input
          ref={typeArRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="opportunity_type_ar"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالإنجليزية</label>
        <input
          dir="ltr"
          ref={typeEnRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="opportunity_type_en"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">بالفرنسية</label>
        <input
          dir="ltr"
          ref={typeFrRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="opportunity_type_fr"
        />
      </div>
      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold select-none">
        ...
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-6">
        <label className="font-bold">
          <div className="flex items-end space-x-2 space-x-reverse">
            <div>
              المساحة (كم<sup>2</sup> )
            </div>
            <div>
              <input type="checkbox" onChange={handleAreaCheckboxChange} />
            </div>
          </div>
        </label>
        {hasArea ? (
          <input
            ref={areaRef}
            className="outline-none border border-muted py-2 px-6"
            min={0}
            pattern="\d+"
            type="number"
            name="opportunity_area"
          />
        ) : (
          <input
            disabled
            value={"غير محددة"}
            type="text"
            className="outline-none border border-muted py-2 px-6"
          />
        )}
      </div>
      <div dir="rtl" className="flex flex-col space-y-2 col-span-6">
        <label className="font-bold">
          <div className="flex items-end space-x-2 space-x-reverse">
            <div>تاريخ الفرصة</div>
            <div>
              <input type="checkbox" onChange={handleTimeCheckboxChange} />
            </div>
          </div>
        </label>
        {hasTime ? (
          <DatePicker
            onChange={(newValue) => setStartedAtDate(newValue)}
            value={startedAtDate}
            clearIcon={null}
            format={`y-MM-dd`}
          />
        ) : (
          <input
            disabled
            value={"غير محدد"}
            type="text"
            className="outline-none border border-muted py-2 px-6"
          />
        )}
      </div>

      <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">رابط معلومات إضافية</label>
        <input
          dir="ltr"
          placeholder="https://..."
          ref={attachmentLinkRef}
          className="outline-none border border-muted py-2 px-6"
          type="url"
          name="opportunity_attachment_link"
        />
      </div>

      <div className="col-span-12 flex">
        <button
          ref={buttonRef}
          onClick={handleOnClickAddButton}
          className="py-3 px-6 bg-blue-600 hover:bg-blue-500 w-full text-white font-bold"
        >
          أضف
        </button>
      </div>
    </>
  );
}
