import React from "react";
import OpportunityTableRow from "./OpportunityTableRow";
import { CgSpinner } from "react-icons/cg";

export default function OpportunityTableBody({
  isLoading,
  isError,
  countries,
}) {
  if (isLoading) {
    return (
      <tbody>
        <tr>
          <td colSpan={5} className="h-80 border">
            <div className="flex items-center justify-center">
              <CgSpinner className="animate-spin" size={40} />
            </div>
          </td>
        </tr>
      </tbody>
    );
  }

  if (isError) {
    return (
      <tbody>
        <tr>
          <td colSpan={5} className="h-80 border ">
            <div
              dir="ltr"
              className="flex items-center justify-center font-bold text-2xl"
            >
              something went wrong !
            </div>
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {countries.map((country) => (
        <OpportunityTableRow key={country.id} country={country} />
      ))}
    </tbody>
  );
}
