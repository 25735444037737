async function getMinerals(token) {

    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });

        const data = await response.json();
        
        if (response.ok) {
            return data.minerals;
        }
        throw new Error(data.message);
    } catch (error) {
        throw error;
    }
}

async function getMineral(token, mineralId) {
    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/${mineralId}`
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.error);
        }
        return data.mineral;
    } catch (error) {
        throw error;
    }
}

async function updateMineral(token, mineralId, newData) {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/${mineralId}/edit`, {
            method: 'POST',
            body: newData,
            headers: {
                'Authorization': token
            }
        });

        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.error);
        }

        return data.error;

    } catch (error) {
        throw error;
    }
}

async function getMineralsCountries(token) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/countries`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });

        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.error);
        }
        return data.minerals;
    } catch (error) {
        throw error;
    }
}

async function getMineralCountriesNotAdded(token, mineralId) {

    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/${mineralId}/countries/not_added`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });

        const data = await response.json();
        if (response.ok) {
            return data.countries;
        }

        throw new Error(data.message);

    } catch (error) {
        throw error;
    }
}

async function addMineralCountry(token, body) {

    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/country`;
        const response = await fetch(url, {
            method: 'POST',
            body: body,
            headers: {
                'Authorization': token
            }
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.error);
        }

        return data.error;
    } catch (error) {
        throw error;
    }
}

async function getMineralCountries(token, mineralId) {

    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/${mineralId}/countries`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.error);
        }

        return data.countries;
    } catch (error) {
        throw error;
    }
}

async function getMineralCountry(token, mineralId, countryId) {

    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/${mineralId}/countries/${countryId}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.error);
        }

        return data.mineral;
    } catch (error) {
        throw error;
    }
}

async function editMineralCountry(token, mineralId, countryId, body) {

    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/${mineralId}/countries/${countryId}`

        const response = await fetch(url, {
            method: 'post',
            body: body,
            headers: {
                'Authorization': token
            }
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.error);
        }

        return data.mineral;
    } catch (error) {
        throw error;
    }
}



// old
async function getMineralsMinify() {

    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/minify`;
        const response = await fetch(url, {
            method: 'GET'
        });

        const data = await response.json();
        if (response.ok) {
            return data.minerals;
        }

        throw new Error(data.message);

    } catch (error) {
        throw error;
    }
}

async function getMineralValueChain(mineralId) {

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/${mineralId}/value_chain`;
    try {
        const response = await fetch(url, {
            method: 'GET'
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorMessage}`);
        }
        const data = await response.json();
        return data.mineral;
    } catch (error) {
        throw error;
    }
}

async function updateMineralValueChain(mineralId, newData) {

    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard/minerals/${mineralId}/value_chain/edit`, {
            method: 'POST',
            body: newData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}


export {
    getMinerals,
    getMineralsMinify,
    getMineral,
    addMineralCountry,
    getMineralsCountries,
    getMineralCountriesNotAdded,
    getMineralCountries,
    getMineralCountry,
    editMineralCountry,

    updateMineral,
    getMineralValueChain,
    updateMineralValueChain
}