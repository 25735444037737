import React, { useEffect, useState } from "react";
import { MdOutlineAdd, MdOutlineArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import axiosClient from "../../axios-client";
import Th from "../../components/globals/Th";

export default function MineralPrices() {
  const [mineralPriceSources, setmineralPriceSources] = useState(null);
  useEffect(() => {
    async function fetchMineralPriceSources() {
      axiosClient
        .get(`/dashboard/minerals/prices/sources`)
        .then(({ data }) => {
          setmineralPriceSources(data.mineral_price_sources);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    }
    fetchMineralPriceSources();
    return () => {};
  }, []);

  return (
    <>
      <div className="col-span-12">
        <div className="flex">
          <Link className="bg-warning p-2 pl-3 hover:bg-warning/80" to={`/`}>
            <MdOutlineArrowForwardIos size={22} />
          </Link>
          <Link
            className="bg-primary py-2 px-4 mr-3 hover:bg-success flex items-center gap-4 text-white"
            to={`/mineral/price`}
          >
            <MdOutlineAdd size={22} />
            <span>إضافة معدن</span>
          </Link>
        </div>
      </div>
      <div className="col-span-12 overflow-x-scroll">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <Th label={`اسم المعدن(العربية)`} />
              <Th label={`رمز`} />
              <Th label={`وحدة القياس بالعربية`} />
              <Th label={`وحدة القياس بالإنجليزية`} />
              <Th label={`وحدة القياس بالفرنسية`} />
              <Th label={`رمز وحدة القياس`} />
              <Th label={`Actions`} />
            </tr>
          </thead>
          {mineralPriceSources && (
            <tbody>
              {mineralPriceSources.map((source) => (
                <tr key={source.id} className="border odd:bg-muted/30 group">
                  <td className="flex items-center justify-center py-2 px-4 border-l group-hover:bg-muted/50 h-full">
                    {source.mineral.name_ar}
                  </td>
                  <td className="py-2 px-4 border-l group-hover:bg-muted/50">
                    {source.symbol}
                  </td>
                  <td className="py-2 px-4 border-l group-hover:bg-muted/50">
                    {source.unit_ar}
                  </td>
                  <td className="py-2 px-4 border-l group-hover:bg-muted/50">
                    {source.unit_en}
                  </td>
                  <td className="py-2 px-4 border-l group-hover:bg-muted/50">
                    {source.unit_fr}
                  </td>
                  <td className="py-2 px-4 border-l group-hover:bg-muted/50">
                    {source.unit_symbol}
                  </td>
                  <td className="py-2 px-4 border-l group-hover:bg-muted/50"></td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}
