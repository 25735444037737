import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdEdit, MdOutlineAdd, MdOutlineArrowForwardIos } from "react-icons/md";
import { useAuth } from "../../contexts/auth/AuthContext";
import axiosClient from "../../axios-client";
import { handleFetchError } from "../../helper";
import { useNavigate } from "react-router-dom";
import MineralType from "../../components/minerals/MineralType";

export default function MineralsRegionPage() {

  const { authDispatch } = useAuth();
  const navigate = useNavigate();
  const [regionMinerals, setRegionMinerals] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  const getRegionMinerals = () => {
    setIsLoading(true);
    axiosClient
      .get(`/minerals/region`)
      .then(({ data }) => {
        console.log(data.minerals);

        setRegionMinerals(data.minerals);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    getRegionMinerals();
  }, []);
  return (
    <>
      <div className="col-span-12">
        <div className="flex mb-2">
          <Link className="bg-warning p-2 hover:bg-warning/80" to={`/`}>
            <MdOutlineArrowForwardIos size={22} />
          </Link>
          <h1 className="text-3xl  border-r-[6px] border-warning pr-2 font-bold">
            المعادن في المنطقة
          </h1>
        </div>
      </div>
      <div className="col-span-12 overflow-x-scroll">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="whitespace-nowrap text-start rounded-tr-xl py-3 px-4 font-semibold bg-primary text-white">
                المعدن
              </th>
              <th className="whitespace-nowrap text-start py-3 px-4 font-semibold bg-primary text-white">
                النوع
              </th>
              <th className="whitespace-nowrap py-3 px-4 font-semibold bg-primary text-white">
                الدول
              </th>
              <th className="whitespace-nowrap py-3 px-4 rounded-tl-xl font-semibold bg-primary text-white">
                Actions
              </th>
            </tr>
          </thead>
          {!isLoading && regionMinerals.map((mineral) => <tr
            key={mineral.id}
            className="bg-muted/50 odd:bg-muted/20 group"
          >
            <td className="py-2 px-4 border-x group-hover:bg-muted/50">
              <div className="flex gap-4">
                <div className="w-10 h-10 overflow-hidden">
                  <img
                    title={mineral.name_ar}
                    className="object-cover w-full h-full"
                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`}
                    alt={`${mineral.name_ar}`}
                  />
                </div>
                <div>
                  <div className="font-bold">
                    {mineral.name_ar}
                  </div>
                  <div>
                    {mineral.name_en}
                  </div>
                </div>
              </div>
            </td>
            <td className="py-2 px-4 border-l group-hover:bg-muted/50">
              <div className="flex flex-wrap gap-2 items-start justify-start">
                <MineralType type={mineral.type} />
              </div>
            </td>
            <td className="py-2 px-4 border-l group-hover:bg-muted/50">
              <div className="flex flex-wrap gap-2 items-start justify-start">
                {mineral.countries.map((country) => {
                  return (
                    <div
                      key={`${country.id}-${mineral.id}`}
                      className="w-10 overflow-hidden border-[1px] border-primary"
                    >
                      <img
                        title={country.name_ar}
                        className="object-cover"
                        src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                        alt={`${country.name_ar} image`}
                      />
                    </div>
                  );
                })}
              </div>
            </td>
            <td className="py-2 px-4 group-hover:bg-muted/50">
              <div className="flex gap-2">
                {mineral.countries.length < 21 && (
                  <Link
                    title="إضافة دولة"
                    className="bg-success p-2 rounded-[6px] hover:bg-success/70 text-white"
                    to={`/minerals/${mineral.id}/countries`}
                  >
                    <MdOutlineAdd size={18} />
                  </Link>
                )}
                {mineral.countries.length > 0 && (
                  <Link
                    className="bg-muted p-2 rounded-[6px] hover:bg-blue-200"
                    to={`/minerals/${mineral.id}/countries/edit`}
                  >
                    <MdEdit size={18} />
                  </Link>
                )}
              </div>
            </td>
          </tr>)}
        </table>
      </div>
    </>
  );
}
