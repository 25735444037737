import moment from "moment";
import React from "react";
import { ConfirmToast } from "react-confirm-toast";
import { FaTrash, FaLink } from "react-icons/fa6";
import { LuFileCheck, LuFileX } from "react-icons/lu";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { OPPORTUNITIES_ACTIONS } from "../../contexts/opportunities/opportunitiesActions";
import { useAuth } from "../../contexts/auth/AuthContext";
import { useOpportunities } from "../../contexts/opportunities/OpportunitiesContext";
import { toast } from "react-toastify";

function TableHeader() {
  return (
    <thead>
      <tr>
        <th className="border-b bg-muted w-6"></th>
        <th className="whitespace-nowrap py-2 px-4 text-start border-white border-b border-x bg-muted">
          العنوان
        </th>
        <th className="whitespace-nowrap py-2 px-4 text-start border-white border-b border-l bg-muted">
          نوع الفرصة
        </th>
        <th className="whitespace-nowrap py-2 px-4 text-start border-white border-b border-l bg-muted">
          الخام
        </th>
        <th className="whitespace-nowrap py-2 px-4 text-start border-white border-b border-l bg-muted">
          المساحة (كم2 )
        </th>
        <th className="whitespace-nowrap py-2 px-4 text-start border-white border-b border-l bg-muted">
          تاريخ الفرصة
        </th>
        <th className="border-b bg-muted">Actions</th>
      </tr>
    </thead>
  );
}

export default function OpportunityTableRowDetails({
  hasData,
  isLoading,
  opportunities,
  countryId,
}) {
  const { opportunitiesDispatch } = useOpportunities();
  const { authState } = useAuth();

  async function deleteOpportunity(countryId, opportunityId) {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/countries/${countryId}/opportunities/${opportunityId}/delete`;
      console.log(url);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: authState.token,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleOnDelete(opportunityId) {
    try {
      deleteOpportunity(countryId, opportunityId);

      opportunitiesDispatch({
        type: OPPORTUNITIES_ACTIONS.FETCH_DATA,
      });

      const url = `${process.env.REACT_APP_API_BASE_URL}/api/dashboard/countries/opportunities`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: authState.token,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      const countries = data.countries;
      opportunitiesDispatch({
        type: OPPORTUNITIES_ACTIONS.SET_COUNTRIES,
        payload: {
          countries: countries,
        },
      });
    } catch (error) {
      opportunitiesDispatch({
        type: OPPORTUNITIES_ACTIONS.SET_IS_ERROR,
      });
    }
  }

  if (isLoading) {
    return (
      <tr>
        <td className="border p-12" colSpan={5}>
          <h2 className="text-center text-xl mb-2 font-bold">
            جاري التحميل الرجاءالانتظار
          </h2>
        </td>
      </tr>
    );
  }

  if (!hasData) {
    return (
      <tr>
        <td className="border p-12" colSpan={5}>
          <h2 className="text-center text-xl mb-2 font-bold">لا توجد فرص</h2>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td className="border p-12 bg-primary" colSpan={5}>
        <table className="table-auto w-full border bg-white">
          <TableHeader />
          <tbody>
            {opportunities.map((opportunity) => (
              <tr
                id={`IdOpportunity${opportunities.id}`}
                key={opportunity.id}
                className="border-b"
              >
                <td className="border-muted border-b border-l">
                  <div className="py-3 px-4  flex justify-center w-full h-full">
                    {opportunity.attachment_link ? (
                      <LuFileCheck
                        title="يتوفر على معلومات اضافية"
                        className="text-success"
                        size={22}
                      />
                    ) : (
                      <LuFileX
                        title="لا يتوفر على معلومات اضافية"
                        className="text-danger"
                        size={22}
                      />
                    )}
                  </div>
                </td>
                <td className="py-3 px-4 border-muted border-b border-l">
                  {opportunity.title_ar}
                </td>
                <td className="py-3 px-4 border-muted border-b border-l">
                  {opportunity.type_ar}
                </td>
                <td className="py-3 px-4 border-muted border-b border-l">
                  {opportunity.minerals_ar}
                </td>
                <td className="py-3 px-4 border-muted border-b border-l">
                  {opportunity.area ? opportunity.area : "غير محددة"}
                </td>
                <td className="py-3 px-4 border-muted border-b border-l">
                  {opportunity.started_at
                    ? moment(opportunity.started_at, "YYYY/MM/DD").year()
                    : "غير محدد"}
                </td>
                <td className="py-3 px-4 min-w-[145px] ">
                  <div className="flex justify-end gap-2">
                    {opportunity.attachment_link && (
                      <Link
                        target="_blanck"
                        to={opportunity.attachment_link}
                        className="bg-muted p-2 rounded-[6px] hover:bg-blue-200"
                      >
                        <FaLink />
                      </Link>
                    )}

                    <Link
                      className="bg-muted p-2 rounded-[6px] hover:bg-blue-200"
                      to={`/opportunities/${opportunity.id}/countries/${countryId}`}
                    >
                      <MdEdit />
                    </Link>

                    <ConfirmToast
                      asModal={true}
                      customCancel={"إلغاء"}
                      customConfirm={"تأكيد"}
                      customFunction={() => handleOnDelete(opportunity.id)}
                      message={"هل أنت متأكد أنك تريد حذف هذه الفرصة؟"}
                      position={"top-left"}
                      showCloseIcon={false}
                    >
                      <button className="bg-danger/50 p-2 rounded-[6px] hover:bg-danger/80  text-white">
                        <FaTrash />
                      </button>
                    </ConfirmToast>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
}
