import React from "react";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";

export default function MineralHasStudies({ studies }) {
  if (studies === null) {
    return (
      <div className="flex text-xs p-1 gap-2 rounded-md bg-danger/20 items-center border-2 border-danger">
        <div className="bg-danger text-white rounded-md">
          <MdOutlineClose className="m-1" />
        </div>
      </div>
    );
  }
  if (studies !== null) {
    return (
      <div className="flex text-xs p-1 gap-2 rounded-md bg-success/20 items-center border-2 border-success">
        <div className="bg-success text-white rounded-md">
          <FaCheck className="m-1" />
        </div>
      </div>
    );
  }
}
