import { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { AUTH_ACTIONS } from "../contexts/auth/authActions";
import { useAuth } from "../contexts/auth/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";

export default function Login() {
  const { authState, authDispatch } = useAuth();

  const navigate = useNavigate();

  const usernameRed = useRef(null);
  const passwordRed = useRef(null);

  if (authState.token) {
    return <Navigate to={`/`} />;
  }

  async function handleOnClickLogin() {
    let isError = false;
    if (!usernameRed.current.value) {
      toast.error("اسم المستخدم مطلوب");
      isError = true;
    }
    if (!passwordRed.current.value) {
      toast.error("كلمة المرور مطلوبة");
      isError = true;
    }
    if (isError) {
      return;
    }
    try {
      const loginFormData = new FormData();
      loginFormData.append("email", usernameRed.current.value);
      loginFormData.append("password", passwordRed.current.value);

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/login`,
        {
          method: "POST",
          body: loginFormData,
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error);
      }

      authDispatch({
        type: AUTH_ACTIONS.LOGIN,
        payload: {
          token: `bearer ${result.token}`,
          user: result.user,
        },
      });

      navigate(`/`);
    } catch (error) {
      toast.error(error.message);
    }
  }
  return (
    <div className="h-screen w-full">
      <ToastContainer />
      <div className="flex h-full w-full items-start justify-center">
        <div className="m-auto w-[512px] border border-muted p-12 shadow-md">
          <div className="flex justify-center">
            <img
              src="/assets/logos/app_logo.svg"
              width={120}
              alt=""
            />
          </div>
          <hr className="my-6" />
          <div className="flex flex-col space-y-3">
            <div className="flex flex-col space-y-2">
              <label dir="rtl" className="font-bold">
                بريد الالكتروني
              </label>
              <input
                ref={usernameRed}
                type="email"
                className="outline-none border border-muted py-3 px-6"
                name="email"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label dir="rtl" className="font-bold">
                كلمة المرور
              </label>
              <input
                ref={passwordRed}
                type="password"
                className="outline-none border border-muted py-3 px-6"
                name="password"
              />
            </div>
            <button
              onClick={handleOnClickLogin}
              className="hover:bg-primary/60 bg-primary py-4 px-6 text-white font-bold"
            >
              تسجيل دخول
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
