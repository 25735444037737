import React from 'react'

export default function MineralType({ type }) {
  if (type === "CLEAN_ENERGY") {
    return 'الطاقة النظيفة';
  } else if (type === "MINERALS_PRICES") {
    return 'أسعار المعادن';
  } else if (type === "INDUSTRIAL_MINERALS") {
    return 'المعادن الصناعية';
  } else {
    return 'none';
  }
}
