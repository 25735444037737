export default {
  menubar: "",
  directionality: "rtl",
  plugins: "directionality lists link image table preview",
  toolbar:
    "removeformat | formatpainter styleselect | bold italic underline strikethrough superscript subscript | link table | forecolor backcolor | bullist numlist | alignleft alignjustify aligncenter alignright | ltr rtl | checklist indent outdent",
  automatic_uploads: false,
  content_style:
    "body { font-family: 'Cairo', sans-serif; color: #1e6f92; font-size:18px }",
  content_css:
    "https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap",
  color_map: [
    "#1e6f92",
    "Custom Blue",
    "#808080",
    "Custom Gray",
    "#dfb662",
    "Custom Gold",
    "#e0ebef",
    "Custom Light Blue",
    "#00CB85",
    "Custom Green",
    "#DD425A",
    "Custom Red",
    "#000",
    "Black",
  ],
  tinycomments_mode: "embedded",
  table_border_styles: [
    {
      title: "Solid",
      value: "solid",
    },
    {
      title: "Dotted",
      value: "dotted",
    },
    {
      title: "Dashed",
      value: "dashed",
    },
  ],
};
