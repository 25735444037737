import { FaCheck } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";

const getLabel = (language) => {
  switch (language) {
    case "ar":
      return "بالعربية";
    case "en":
      return "بالإنجليزية";
    case "fr":
      return "بالفرنسية";
    default:
      return;
  }
};

export default function MineralHasValueChains({ valueChain, language }) {

  if (
    !valueChain ||
    (!valueChain[`image_${language}`] && !valueChain[`source_${language}`])
  ) {
    return (
      <div className="flex text-xs p-1 pl-2 gap-2 rounded-md bg-danger/20 items-center border-2 border-danger">
        <div className="bg-danger text-white rounded-md">
          <MdOutlineClose className="m-1" />
        </div>
        <div className="font-bold">{getLabel(language)}</div>
      </div>
    );
  }
  if (valueChain[`image_${language}`] && valueChain[`source_${language}`]) {
    return (
      <div className="flex text-xs p-1 pl-2 gap-2 rounded-md bg-success/20 items-center border-2 border-success">
        <div className="bg-success text-white rounded-md">
          <FaCheck className="m-1" />
        </div>
        <div className="font-bold">{getLabel(language)}</div>
      </div>
    );
  }
}
