import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import editorConfig from "../../config/editorConfig";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdClose, MdOutlineArrowForwardIos } from "react-icons/md";
import { useAuth } from "../../contexts/auth/AuthContext";
import { TbCameraUp } from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";
import axiosClient from "../../axios-client";
import { handleFetchError } from "../../helper";

export default function MineralEditPage() {
  const { authDispatch } = useAuth();

  const navigate = useNavigate();
  const { mineralId } = useParams();

  const [mineral, setMineral] = useState(null);

  const buttonRef = useRef(null);

  const nameArRef = useRef("");
  const nameEnRef = useRef("");
  const nameFrRef = useRef("");

  const symbolRef = useRef("");
  const typeRef = useRef("");
  const imageRef = useRef(null);

  const bodyArRef = useRef("");
  const bodyEnRef = useRef("");
  const bodyFrRef = useRef("");

  const sourceImageRef = useRef(null);
  const sourceBodyArRef = useRef("");
  const sourceBodyEnRef = useRef("");
  const sourceBodyFrRef = useRef("");

  const historyBodyArRef = useRef("");
  const historyBodyEnRef = useRef("");
  const historyBodyFrRef = useRef("");

  const compoundBodyArRef = useRef("");
  const compoundBodyEnRef = useRef("");
  const compoundBodyFrRef = useRef("");

  const valueChainArImageRef = useRef(null);
  const valueChainEnImageRef = useRef(null);
  const valueChainFrImageRef = useRef(null);

  const valueChainArSourceRef = useRef(null);
  const valueChainEnSourceRef = useRef(null);
  const valueChainFrSourceRef = useRef(null);

  const valueChainLinkRef = useRef(null);

  const studiesLinkArRef = useRef("");
  const studiesLinkEnRef = useRef("");
  const studiesLinkFrRef = useRef("");

  // useSatates
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSourceImage, setSelectedSourceImage] = useState(null);
  const [selectedValueChainArImage, setSelectedValueChainArImage] =
    useState(null);
  const [selectedValueChainEnImage, setSelectedValueChainEnImage] =
    useState(null);
  const [selectedValueChainFrImage, setSelectedValueChainFrImage] =
    useState(null);

  const [isLoading, setIsLoading] = useState(true);

  async function handleOnClickButtonUpdateMineral() {
    buttonRef.current.disabled = true;
    buttonRef.current.innerText = "يرجى الانتظار...";

    const payload = {
      name_ar: nameArRef.current?.value || "",
      name_en: nameEnRef.current?.value || "",
      name_fr: nameFrRef.current?.value || "",
      symbol: symbolRef.current?.value || "",
      image: imageRef.current?.files[0] || "",
      type: typeRef.current?.value || "",
      body_ar: bodyArRef.current.getContent(),
      body_en: bodyEnRef.current.getContent(),
      body_fr: bodyFrRef.current.getContent(),
      source_image: sourceImageRef.current?.files[0] || "",
      source_body_ar: sourceBodyArRef.current.getContent(),
      source_body_en: sourceBodyEnRef.current.getContent(),
      source_body_fr: sourceBodyFrRef.current.getContent(),
      history_body_ar: historyBodyArRef.current.getContent(),
      history_body_en: historyBodyEnRef.current.getContent(),
      history_body_fr: historyBodyFrRef.current.getContent(),
      compound_body_ar: compoundBodyArRef.current.getContent(),
      compound_body_en: compoundBodyEnRef.current.getContent(),
      compound_body_fr: compoundBodyFrRef.current.getContent(),
      studies_link_ar: studiesLinkArRef.current?.value || "",
      studies_link_en: studiesLinkEnRef.current?.value || "",
      studies_link_fr: studiesLinkFrRef.current?.value || "",
      value_chain_image_ar: valueChainArImageRef.current?.files[0] || "",
      is_value_chain_ar_deleted: selectedValueChainArImage == null,
      value_chain_image_en: valueChainEnImageRef.current?.files[0] || "",
      is_value_chain_en_deleted: selectedValueChainEnImage == null,
      value_chain_image_fr: valueChainFrImageRef.current?.files[0] || "",
      is_value_chain_fr_deleted: selectedValueChainFrImage == null,
      value_chain_source_ar: valueChainArSourceRef.current?.value || "",
      value_chain_source_en: valueChainEnSourceRef.current?.value || "",
      value_chain_source_fr: valueChainFrSourceRef.current?.value || "",
      value_chain_link: valueChainLinkRef.current?.value || "",
    };

    axiosClient
      .post(`/minerals/${mineralId}/update`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        toast.success(`تم تعديل ${nameArRef.current?.value} بنجاح`);
        navigate("/minerals");
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        buttonRef.current.disabled = false;
        buttonRef.current.innerText = "تعديل";
      });
  }

  function handleOnChangeValueChainAr() {
    setSelectedValueChainArImage(
      URL.createObjectURL(valueChainArImageRef.current.files[0])
    );
  }

  function handleOnChangeValueChainEn() {
    setSelectedValueChainEnImage(
      URL.createObjectURL(valueChainEnImageRef.current.files[0])
    );
  }

  function handleOnChangeValueChainFr() {
    setSelectedValueChainFrImage(
      URL.createObjectURL(valueChainFrImageRef.current.files[0])
    );
  }

  useEffect(() => {
    async function fetchMineral() {
      setIsLoading(true);

      axiosClient
        .get(`/minerals/${mineralId}`)
        .then(({ data }) => {
          const mineral = data.mineral;

          setSelectedImage(
            mineral.image
              ? `${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.image}`
              : null
          );
          setSelectedSourceImage(
            mineral.source_image
              ? `${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.source_image}`
              : null
          );
          setSelectedValueChainArImage(
            mineral.value_chains?.image_ar
              ? `${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.value_chains.image_ar}`
              : null
          );
          setSelectedValueChainEnImage(
            mineral.value_chains?.image_en
              ? `${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.value_chains.image_en}`
              : null
          );
          setSelectedValueChainFrImage(
            mineral.value_chains?.image_fr
              ? `${process.env.REACT_APP_API_BASE_URL}/storage/minerals${mineral.value_chains.image_fr}`
              : null
          );

          setMineral(mineral);
        })
        .catch((error) => {
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    fetchMineral(mineralId);
    return () => { };
  }, [mineralId]);

  if (isLoading) {
    return (
      <div className={`col-span-12`}>
        <div className="flex items-center justify-center h-screen">
          <CgSpinner className="animate-spin" size={40} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="col-span-12">
        <div className="flex mb-2">
          <Link
            className="bg-warning p-2 hover:bg-warning/80 duration-150 ease-in-out transform"
            to={`/minerals`}
          >
            <MdOutlineArrowForwardIos size={22} />
          </Link>
          <h1 className="text-3xl border-r-[6px] border-warning pr-2 font-bold">
            تعديل معدن ({mineral.name_ar})
          </h1>
        </div>
      </div>

      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">صورة المعدن*</label>
        {!selectedImage ? (
          <div className="h-[250px] w-full bg-muted flex items-center justify-center">
            <TbCameraUp size={64} strokeWidth={1} />
          </div>
        ) : (
          <div className="relative ">
            <button
              className="p-1 bg-danger/70 hover:bg-danger text-white absolute top-0 right-0"
              onClick={() => {
                setSelectedImage(null);
                imageRef.current.value = null;
              }}
            >
              <MdClose size={22} />
            </button>
            <img
              className="h-[250px] w-full object-contain border"
              src={selectedImage}
              alt="mineral"
            />
          </div>
        )}
        <label className="w-full cursor-pointer bg-success py-2 px-4 text-white flex justify-center hover:bg-primary duration-150 ease-in-out transform">
          حدد صورة
          <input
            ref={imageRef}
            className="hidden"
            onChange={() =>
              setSelectedImage(URL.createObjectURL(imageRef.current.files[0]))
            }
            type="file"
            name="mineral_image"
            accept="image/webp"
          />
        </label>
      </div>

      <div className="col-span-8"></div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">اسم معدن بالعربية*</label>
        <input
          defaultValue={mineral.name_ar}
          ref={nameArRef}
          dir="rtl"
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="mineral_name_ar"
        />
      </div>

      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">اسم معدن بالإنجليزية*</label>
        <input
          defaultValue={mineral.name_en}
          ref={nameEnRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="mineral_name_en"
        />
      </div>

      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">اسم معدن بالفرنسية</label>
        <input
          defaultValue={mineral.name_fr}
          ref={nameFrRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="mineral_name_fr"
        />
      </div>

      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">رمز معدن*</label>
        <input
          defaultValue={mineral.symbol}
          ref={symbolRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="mineral_symbol"
        />
      </div>

      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">نوع*</label>
        <select
          defaultValue={mineral.type}
          ref={typeRef}
          className="outline-none border border-muted py-1.5 px-6"
          name="mineral_type"
        >
          <option value="CLEAN_ENERGY">معادن الطاقة النظيفة</option>
          <option value="INDUSTRIAL_MINERALS">معادن الصناعية</option>
          <option value="MINERALS_PRICES">أسعار المعادن</option>
        </select>
      </div>
      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        وصف
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالعربية *</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (bodyArRef.current = editor)}
            initialValue={mineral.body_ar}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالإنجليزية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (bodyEnRef.current = editor)}
            initialValue={mineral.body_en}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالفرنسية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (bodyFrRef.current = editor)}
            initialValue={mineral.body_fr}
          />
        </div>
      </div>

      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        تاريخ
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالعربية *</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (historyBodyArRef.current = editor)}
            initialValue={mineral.history_body_ar}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالإنجليزية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (historyBodyEnRef.current = editor)}
            initialValue={mineral.history_body_en}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالفرنسية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (historyBodyFrRef.current = editor)}
            initialValue={mineral.history_body_fr}
          />
        </div>
      </div>

      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        مصدر
      </div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">صورة *</label>
        {!selectedSourceImage ? (
          <div className="h-[250px] w-full bg-muted flex items-center justify-center">
            <TbCameraUp size={64} strokeWidth={1} />
          </div>
        ) : (
          <div className="relative ">
            <button
              className="p-1 bg-danger/70 hover:bg-danger text-white absolute top-0 right-0"
              onClick={() => {
                setSelectedSourceImage(null);
                sourceImageRef.current.value = null;
              }}
            >
              <MdClose size={22} />
            </button>
            <img
              className="h-[250px] w-full object-contain border"
              src={selectedSourceImage}
              alt=""
            />
          </div>
        )}
        <label className="w-full cursor-pointer bg-success py-2 px-4 text-white flex justify-center hover:bg-primary duration-150 ease-in-out transform">
          حدد صورة
          <input
            ref={sourceImageRef}
            className="hidden"
            onChange={() =>
              setSelectedSourceImage(
                URL.createObjectURL(sourceImageRef.current.files[0])
              )
            }
            type="file"
            name="image"
            accept="image/webp"
          />
        </label>
      </div>
      <div className="col-span-8"></div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالعربية*</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (sourceBodyArRef.current = editor)}
            initialValue={mineral.source_body_ar}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالإنجليزية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (sourceBodyEnRef.current = editor)}
            initialValue={mineral.source_body_en}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالفرنسية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (sourceBodyFrRef.current = editor)}
            initialValue={mineral.source_body_fr}
          />
        </div>
      </div>
      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        المركبات
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالعربية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (compoundBodyArRef.current = editor)}
            initialValue={mineral.compound_body_ar}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالإنجليزية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (compoundBodyEnRef.current = editor)}
            initialValue={mineral.compound_body_en}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">بالفرنسية</label>
        <div>
          <Editor
            apiKey={process.env.REACT_APP_TINY_KEY}
            init={editorConfig}
            onInit={(evt, editor) => (compoundBodyFrRef.current = editor)}
            initialValue={mineral.compound_body_fr}
          />
        </div>
      </div>
      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        سلسلة القيمة
      </div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">صورة بالعربية</label>
        {!selectedValueChainArImage ? (
          <div className="h-[250px] w-full bg-muted flex items-center justify-center">
            <TbCameraUp size={64} strokeWidth={1} />
          </div>
        ) : (
          <div className="relative ">
            <button
              className="p-1 bg-danger/70 hover:bg-danger text-white absolute top-0 right-0"
              onClick={() => {
                setSelectedValueChainArImage(null); // NULL GHA THIYAD FULL GHADI TB9A
                valueChainArImageRef.current.value = null;
              }}
            >
              <MdClose size={22} />
            </button>
            <img
              className="h-[250px] w-full object-contain border"
              src={selectedValueChainArImage}
            />
          </div>
        )}
        <label className="w-full cursor-pointer bg-success py-2 px-4 text-white flex justify-center hover:bg-primary duration-150 ease-in-out transform">
          حدد صورة
          <input
            ref={valueChainArImageRef}
            className="hidden"
            onChange={() => handleOnChangeValueChainAr()}
            type="file"
            name="value_chain_image_ar"
            accept="image/webp"
          />
        </label>
        <label className="font-bold">اسم مصدر بالعربية</label>
        <input
          defaultValue={mineral.value_chains?.source_ar}
          ref={valueChainArSourceRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="value_chain_source_ar"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">صورة بالإنجليزية</label>
        {!selectedValueChainEnImage ? (
          <div className="h-[250px] w-full bg-muted flex items-center justify-center">
            <TbCameraUp size={64} strokeWidth={1} />
          </div>
        ) : (
          <div className="relative ">
            <button
              className="p-1 bg-danger/70 hover:bg-danger text-white absolute top-0 right-0"
              onClick={() => {
                setSelectedValueChainEnImage(null);

                valueChainEnImageRef.current.value = null;
              }}
            >
              <MdClose size={22} />
            </button>
            <img
              className="h-[250px] w-full object-contain border"
              src={selectedValueChainEnImage}
            />
          </div>
        )}
        <label className="w-full cursor-pointer bg-success py-2 px-4 text-white flex justify-center hover:bg-primary duration-150 ease-in-out transform">
          حدد صورة
          <input
            ref={valueChainEnImageRef}
            className="hidden"
            onChange={() => handleOnChangeValueChainEn()}
            type="file"
            name="value_chain_image_en"
            accept="image/webp"
          />
        </label>
        <label className="font-bold">اسم مصدر بالإنجليزية</label>
        <input
          defaultValue={mineral.value_chains?.source_en}
          ref={valueChainEnSourceRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="value_chain_source_en"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-4">
        <label className="font-bold">صورة بالفرنسية</label>
        {!selectedValueChainFrImage ? (
          <div className="h-[250px] w-full bg-muted flex items-center justify-center">
            <TbCameraUp size={64} strokeWidth={1} />
          </div>
        ) : (
          <div className="relative ">
            <button
              className="p-1 bg-danger/70 hover:bg-danger text-white absolute top-0 right-0"
              onClick={() => {
                setSelectedValueChainFrImage(null);
                valueChainFrImageRef.current.value = null;
              }}
            >
              <MdClose size={22} />
            </button>
            <img
              className="h-[250px] w-full object-contain border"
              src={selectedValueChainFrImage}
            />
          </div>
        )}
        <label className="w-full cursor-pointer bg-success py-2 px-4 text-white flex justify-center hover:bg-primary duration-150 ease-in-out transform">
          حدد صورة
          <input
            ref={valueChainFrImageRef}
            className="hidden"
            onChange={() => handleOnChangeValueChainFr()}
            type="file"
            name="value_chain_image_fr"
            accept="image/webp"
          />
        </label>

        <label className="font-bold">اسم مصدر بالفرنسية</label>
        <input
          defaultValue={mineral.value_chains?.source_fr}
          ref={valueChainFrSourceRef}
          className="outline-none border border-muted py-2 px-6"
          type="text"
          name="value_chain_source_fr"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">رابط مصدر</label>
        <input
          defaultValue={mineral.value_chains?.link}
          ref={valueChainLinkRef}
          className="outline-none border border-muted py-2 px-6 cursor-pointer"
          type="text"
          name="studies_ar_file"
        />
      </div>
      <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold">
        الدراسات
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">رابط الدراسات بالعربية</label>
        <input
          defaultValue={mineral.studies?.link_ar}
          ref={studiesLinkArRef}
          className="outline-none border border-muted py-2 px-6 cursor-pointer"
          type="text"
          name="studies_ar_file"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">رابط الدراسات بالإنجليزية</label>
        <input
          defaultValue={mineral.studies?.link_en}
          ref={studiesLinkEnRef}
          className="outline-none border border-muted py-2 px-6 cursor-pointer"
          type="text"
          name="studies_en_file"
        />
      </div>
      <div className="flex flex-col space-y-2 col-span-12">
        <label className="font-bold">رابط الدراسات بالفرنسية</label>
        <input
          defaultValue={mineral.studies?.link_fr}
          ref={studiesLinkFrRef}
          className="outline-none border border-muted py-2 px-6 cursor-pointer"
          type="text"
          name="studies_fr_file"
        />
      </div>
      <div className="col-span-12 flex">
        <button
          ref={buttonRef}
          onClick={handleOnClickButtonUpdateMineral}
          className="py-3 px-6 bg-blue-600 hover:bg-blue-500 w-full text-white font-bold duration-150 ease-in-out transform"
        >
          تعديل
        </button>
      </div>
    </>
  );
}
