import { Navigate, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SideMenu from "./components/includes/side_menu/SideMenu";
import { useAuth } from "./contexts/auth/AuthContext";

export default function Layout() {
  const { authState } = useAuth();

  if (!authState.token) {
    return <Navigate to={`/login`} />;
  }

  return (
    <div className="w-full text-primary ">
      <ToastContainer />
      <div dir="rtl" className="grid grid-cols-12 w-full h-screen ">
        <SideMenu />
        <div className="w-full col-span-9 xl:col-span-10">
          <div className="w-full gap-2 grid grid-cols-12 p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
